'use strict';

var assign = require(114);

var BpmnModdle = require(16);

var packages = {
  bpmn: require(19),
  bpmndi: require(20),
  dc: require(21),
  di: require(22),
  bioc: require(18)
};

module.exports = function(additionalPackages, options) {
  return new BpmnModdle(assign({}, packages, additionalPackages), options);
};
