module.exports = {
  __init__: [ 'selectionVisuals', 'selectionBehavior' ],
  __depends__: [
    require(169),
    require(171)
  ],
  selection: [ 'type', require(174) ],
  selectionVisuals: [ 'type', require(176) ],
  selectionBehavior: [ 'type', require(175) ]
};
